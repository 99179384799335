/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Dialog,
    /*     Form,
        FormButton, */
    FormInput,
    SvgIconSizeValue,
    Tooltip,
} from "@fluentui/react-northstar";
import { AddIcon, NotesIcon } from "@fluentui/react-icons-northstar";
import * as Backend from "../actions/backendCalls";
import * as Constants from "../utils/constants";

// eslint-disable-next-line react/prop-types
type DescriptionDialogTypes = {
    hasDescription: string | null;
    id: string | undefined;
    token: string;
    submitReady: any;
};

const DescriptionDialog = ({
    hasDescription,
    token,
    id,
    submitReady,
}: DescriptionDialogTypes) => {
    const [popup, setPopup] = useState<boolean>(false);
    const [ready, setReady] = useState<boolean>(false);
    const [iconSize, setIconSize] = useState<SvgIconSizeValue>("small");
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setIconSize(
            window.innerWidth >= Constants.MOBILEWIDTH ? "small" : "smaller"
        );
    }, [window.innerWidth]);

    useEffect(() => {
        if (ready) {
            // console.log("Submit Ready")
            submitReady(ready);
            setReady(false);
        }
    }, [ready]);

    const handleOnSubmit = async (description) => {
        // const description = e.target["description"].value;
        // console.log("Submit clicked");
        // console.log("Description:");
        // console.log(description);
        setPopup(false);

        const body = {
            description: description,
        };
        await Backend.updateDescription(token, body, id);
        setReady(true);
    };

    return (
        <Dialog
            styles={({ theme: { siteVariables } }) => ({
                color: siteVariables.colorScheme.brand.foreground,
            })}
            style={{ minWidth: "85%" }}
            open={popup}
            backdrop
            cancelButton={{
                content: "Cancel",
                styles: {
                    marginLeft: "0.574rem",
                },
            }}
            footer={{ styles: { direction: "rtl" } }}
            confirmButton={"Submit"}
            onCancel={() => setPopup(false)}
            onConfirm={() => {
                if (inputRef !== null && inputRef.current !== null) {
                    console.log(inputRef.current.value);
                    handleOnSubmit(inputRef.current.value);
                }
            }}
            trapFocus={{
                forceFocusInsideTrapOnComponentUpdate: true,
                forceFocusInsideTrapOnOutsideFocus: true,
            }}
            content={
                <FormInput
                    autoComplete="off"
                    maxLength={100}
                    defaultValue={hasDescription as string | undefined}
                    fluid
                    // required
                    name="description"
                    ref={inputRef}
                />
            }
            onKeyPress={(e) => {
                if (e.key === "Enter") {
                    if (inputRef !== null && inputRef.current !== null) {
                        console.log(inputRef.current.value);
                        handleOnSubmit(inputRef.current.value);
                    }
                }
            }}
            header="Add description"
            trigger={
                <Button
                    icon={
                        <Tooltip
                            trigger={
                                hasDescription ? (
                                    <NotesIcon size={iconSize} />
                                ) : (
                                    <AddIcon size={iconSize} />
                                )
                            }
                            content={hasDescription}
                        />
                    }
                    onClick={() => setPopup(true)}
                    text
                    iconOnly
                />
            }
        />
    );
};

export default DescriptionDialog;
