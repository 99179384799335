import React, { useEffect, useState } from "react";
import { Dropdown, DropdownProps } from "@fluentui/react-northstar";

interface IYearDropdown {
    projectChangeParentHandler: any;
}
export const YearDropdown = ({ projectChangeParentHandler }: IYearDropdown) => {
    const [years, setYears] = useState<number[]>();
    const [year, setYear] = useState<number | null>();

    useEffect(() => {
        const _range = 20;
        const _year = new Date().getFullYear();
        const _years = Array.from(
            new Array(20),
            (_val, index) => index + _year - _range / 2
        );
        setYear(_year);
        setYears(_years);
    }, []);

    useEffect(() => {
        projectChangeParentHandler(year);
    }, [year]);

    const handleOnChange = (_e: any, d: DropdownProps) => {
        setYear(d.value?.valueOf() as number);
    };

    return (
        <>
            <Dropdown
                items={years}
                placeholder="Select year"
                checkable
                fluid
                value={year}
                getA11ySelectionMessage={{
                    onAdd: (item) => `${item} has been selected.`,
                }}
                onChange={handleOnChange}
            />
        </>
    );
};
