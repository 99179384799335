import * as React from "react";
import { Alert, Text } from "@fluentui/react-northstar";

type AlertTypes = {
    msg: string;
    handleDisMiss: any;
    success: boolean;
};

const AlertExampleUrgent = ({ msg, handleDisMiss, success }: AlertTypes) => (
    <Alert
        success={success}
        danger={!success}
        content={
            <Text color={success ? "green" : "red"} weight="bold">
                {msg}
            </Text>
        }
        dismissible
        dismissAction={{ "aria-label": "close" }}
        // variables={{ urgent: success }}
        onVisibleChange={() => handleDisMiss(false)}
    />
);

export default AlertExampleUrgent;
