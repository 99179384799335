import React from "react";
import * as microsoftTeams from "@microsoft/teams-js";

import "../utils/css/App.css";
import "../utils/css/Calendar.css";
import jwt_decode from "jwt-decode";
import * as Constants from "../utils/constants";
import {
    Provider,
    mergeThemes,
    teamsTheme,
    teamsDarkTheme,
    Text,
    ThemePrepared,
    TeamsThemeStylesProps,
} from "@fluentui/react-northstar";

import * as Backend from "../actions/backendCalls";
import WeekTable from "./WeekTable";

import {
    customTheme,
    mobileProviderStyle /* customMobileTheme */,
} from "./ThemeProvider";
import CustomLoader from "./CustomLoader";

class Tab extends React.Component {
    state: {
        theme: string;
        context: object;
        dbUsers: object;
        jwtoken: string;
        decodedToken: any;
        isMobile: boolean;
        userExists: boolean;
        isAdmin: boolean;
        errorMsg: string;
        error: boolean;
    };
    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.state = {
            theme: "",
            context: {},
            dbUsers: {},
            jwtoken: "",
            decodedToken: {},
            isMobile: window.innerWidth >= Constants.MOBILEWIDTH ? false : true,
            userExists: false,
            isAdmin: false,
            errorMsg: "",
            error: false,
        };
    }

    getUsers = (token) => {
        Backend.getUsers(token)
            .then((users) => {
                this.setState({ dbUsers: users });
                let index;
                if (users) {
                    if (users.length > 0) {
                        if (
                            users.some((obj, i) => {
                                if (
                                    obj.full_name ===
                                    this.state.decodedToken.name
                                ) {
                                    index = i;
                                    return true;
                                }
                            })
                        ) {
                            // console.log("Is Admin? " + users[index].is_admin);
                            this.setState({ isAdmin: users[index].is_admin }); //set isAdmin status
                            // console.log("User exists!");
                            this.setState({ userExists: true });
                        } else {
                            console.log("Lets make new user!");
                            this.setNewUser(token);
                        }
                    } else {
                        throw new Error("User list is empty");
                    }
                } else {
                    throw new Error("No users found");
                }
            })
            .catch((err) => {
                if (err.status === 409) {
                    this.setState({
                        error: true,
                        errorMsg:
                            "Frontend and backend are not in sync. Please wait a few minutes and try again. If that doesn't help please contact Toni.K or Levi",
                    });
                    return;
                }
                console.error(`Error while getting users: ${err}`);
                this.setState({
                    error: true,
                    errorMsg: `Error while getting users: ${err}. Try to reload page. If that doesn't help please contact Toni or Levi`,
                });
            });
    };

    setNewUser = (token) => {
        const body = {
            app_settings: { theme: this.state.theme, visible_projects: [] },
            full_name: this.state.decodedToken.name,
            email: this.state.decodedToken.upn,
            oid: this.state.decodedToken.oid,
            disabled: false, //TODO:??
            is_admin: false, //TODO:??
        };

        Backend.setNewUser(token, body)
            .then(() => {
                this.setState({ userExists: true });
            })
            .catch((err) => {
                this.setState({
                    error: true,
                    errorMsg: err,
                });
            });
    };

    updateMobileState = () => {
        window.innerWidth >= Constants.MOBILEWIDTH
            ? this.setState({ isMobile: false })
            : this.setState({ isMobile: true });
    };

    componentDidUpdate() {
        window.addEventListener("resize", this.updateMobileState);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateMobileState);
    }

    //React lifecycle method that gets called once a component has finished mounting
    //Learn more: https://reactjs.org/docs/react-component.html#componentdidmount
    componentDidMount() {
        // Get the user context from Teams and set it in the state
        microsoftTeams.app
            .getContext()
            .then((context) => {
                this.setState({ context: context });
            })
            .catch((err) => {
                console.log(`Error while getting context: ${err}`);
                this.setState({
                    error: true,
                    errorMsg: `Error while getting context: ${err}. Try to reload page. If that doesn't help please contact Toni or Levi`,
                });
            });

        microsoftTeams.app.registerOnThemeChangeHandler((theme) => {
            if (theme !== this.state.theme) {
                this.setState({ theme });
            }
        });

        // Get Auth token from AD
        const authTokenRequest: microsoftTeams.authentication.AuthTokenRequestParameters =
            {
                resources: [],
                silent: false,
            };

        microsoftTeams.authentication
            .getAuthToken(authTokenRequest)
            .then((response) => {
                if (response) {
                    const decodedToken = jwt_decode(response);
                    // console.log(decodedToken);
                    this.setState({
                        jwtoken: response,
                        decodedToken: decodedToken,
                    });
                    this.getUsers(response);
                } else {
                    console.error("Failure: " + response);
                    this.setState({
                        error: true,
                        errorMsg:
                            "Login failure. Try to reload page. If that doesn't help please contact Toni.K or Levi",
                    });
                }
            })
            .catch((error) => {
                console.error("Failure: " + error);
                this.setState({
                    error: true,
                    errorMsg:
                        "Login failure. Try to reload page. If that doesn't help please contact Toni.K or Levi",
                });
            });

        microsoftTeams.app.notifySuccess();

        window.addEventListener("onload", this.updateMobileState);
    }
    render() {
        const providerStyle = this.state.isMobile ? mobileProviderStyle : {};
        const _customTheme =
            /* this.state.isMobile ? customMobileTheme :  */ customTheme; //custom theme props
        let theme: ThemePrepared<TeamsThemeStylesProps> = teamsTheme; //default theme

        let _currentTheme = this.state.theme; // theme (dark, light, highres)
        if (!_currentTheme) {
            _currentTheme = this.state.context["theme"];
        }
        if (_currentTheme === "default" || _currentTheme === "light") {
            theme = teamsTheme;
        } else if (_currentTheme === "dark" || _currentTheme === undefined) {
            theme = teamsDarkTheme;
        }

        theme = mergeThemes(theme, _customTheme); //merge custom theme with teams theme

        return (
            <Provider theme={theme} style={providerStyle}>
                {!this.state.error ? (
                    <div
                        style={{
                            paddingLeft: 20,
                            paddingRight: 50,
                            paddingBottom: 20,
                            minHeight: "100vh",
                            minWidth: "99vw",
                        }}
                    >
                        {/* <p></p> */}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {!this.state.userExists ? (
                                <CustomLoader msg="Fetching users" />
                            ) : (
                                <WeekTable
                                    token={this.state.jwtoken}
                                    decodedToken={this.state.decodedToken}
                                />
                            )}
                        </div>
                        {/* <div> */}
                        {/* <h1>isMobile: {this.state.isMobile.toString()}</h1> */}
                        {/* <h1> {window.innerWidth.toString()}</h1> */}
                        {/* </div> */}
                    </div>
                ) : (
                    <div
                        style={{
                            minHeight: "100vh",
                            minWidth: "100vw",
                        }}
                    >
                        <Text
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                            weight="bold"
                            size="large"
                            content={this.state.errorMsg}
                        />
                    </div>
                )}
            </Provider>
        );
    }
}
export default Tab;
