import moment from "moment";

export const pad0Left = (num: string | number) => String("0" + num).slice(-2);

//build time duration in format hh:mm:ss
export const getTimeDuration = (startTime: any, endTime: any) => {
    if (typeof startTime === "string" || typeof startTime === "number") {
        startTime = new Date(startTime);
    }

    if (typeof endTime === "string" || typeof endTime === "number") {
        endTime = new Date(endTime);
    }

    const durationInSecond = Math.round((endTime - startTime) / 1000);
    const second = durationInSecond % 60;
    let durationInMinute = 0;
    let minute = 0;
    if (durationInSecond > second) {
        durationInMinute = (durationInSecond - second) / 60;
        minute = durationInMinute % 60;
    }
    let durationInHour = 0;
    let hour = 0;
    if (durationInMinute > minute) {
        durationInHour = (durationInMinute - minute) / 60;
        hour = durationInHour % 60;
    }
    return pad0Left(hour) + ":" + pad0Left(minute) + ":" + pad0Left(second);
};

/*
@date Date
@return string 
"10:10 PM"
*/
export const toAmPm = (date: any) => {
    if (typeof date === "string" || typeof date === "number") {
        date = new Date(date);
    }

    if (date instanceof Date) {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const amPm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        return hours + ":" + pad0Left(minutes) + " " + amPm;
    } else {
        console.error("input is not a date");
        return false;
    }
};

/*
@amPm: 10:10 PM
@return {
  hours: 10,
  minutes: 10,
  amPm: 'pm'
}
*/
export const fromAmPM = (amPm: string) => {
    const amPmRegExp = /(\d+)\s*:\s*(\d+)\s*(AM|PM)/i;
    const matches = amPmRegExp.exec(amPm);
    if (matches) {
        return {
            hours: parseInt(matches[1], 10),
            minutes: parseInt(matches[2], 10),
            amPm: matches[3].toLowerCase(),
        };
    } else {
        return null;
    }
};

/*
  Calculate a Date object from user start time input
  @amPm string user input of start time in format hh:mm:ss
  @now Date current time
  @return Date 
  if time is greater than now so return Date with one day back
  e.g. if @now : Jan 2 2017, 10:10PM and @amPM is 10:20PM then @return : Jan 1 2017, 10:20 PM
  e.g. if @now : Jan 2 2017, 10:10PM and @amPM is 9:20PM then @return : Jan 2 2017, 9:20 PM
  */
export const fromAmPmToDate = (amPm: any, now: any) => {
    const amPmObj = fromAmPM(amPm);
    if (amPmObj) {
        const hours =
            amPmObj.amPm === "pm" ? amPmObj.hours + 12 : amPmObj.hours;
        const date = new Date(now.getTime());
        date.setHours(hours, amPmObj.minutes);
        if (date > now) {
            date.setDate(date.getDate() - 1);
        }
        return date;
    }
    return null;
};

// Get all week days based on the start date of that week
export const getWeekDays = (weekStart: any) => {
    // 0: prev sunday 00:00:00
    // 1-7: current week
    // 8: next monday 00:00:00 <-- not any more
    // console.log(weekStart);
    // const days: any[] = [moment(weekStart).add(1, "days").toDate()];
    const days: any[] = [
        moment(weekStart).subtract(1, "days").utc(true).valueOf(),
    ];

    for (let i = 0; i < 8; i++) {
        days.push(moment(weekStart).add(i, "days").utc(true).valueOf());
    }
    // days.push(moment(weekStart).add(7, "days").toDate());
    // console.log(days);
    return days;
};

// Get the range for one week (start date & end date of that week)
export const getWeekRange = (date) => {
    return {
        from: moment(date).startOf("isoWeek").toDate(),
        to: moment(date).endOf("isoWeek").toDate(),
    };
};

// Get weekdays based on specific date
export const getThisWeek = (d) => {
    // console.log(d);
    const ret = getWeekDays(getWeekRange(d).from);
    return ret;
};

// Get previous week monday date
export const previousWeekMonday = (d) => {
    d = new Date(d);
    if (!d.getDay())
        return new Date(d.getFullYear(), d.getMonth(), d.getDate() - 6);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() - d.getDay());
};

// Get next week monday date
export const nextWeekMonday = (d) => {
    d = new Date(d);
    if (!d.getDay())
        return new Date(d.getFullYear(), d.getMonth(), d.getDate() + 13);
    return new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate() - d.getDay() + 7
    );
};

// Get dates for previous week
export const getPrevWeek = (d) => {
    const currWeekMonday = getWeekRange(new Date()).from;
    let useDate;
    if (d === currWeekMonday) {
        // console.log("Use currentWeekMonday");
        useDate = currWeekMonday;
    } else {
        // console.log("Use d");
        useDate = d;
    }
    return getWeekDays(getWeekRange(previousWeekMonday(useDate)).from);
};

// Get dates for next week
export const getNextWeek = (d) => {
    const currWeekMonday = getWeekRange(new Date()).from;
    let useDate;
    if (d === currWeekMonday) {
        // console.log("Use currentWeekMonday");
        useDate = currWeekMonday;
    } else {
        // console.log("Use d");
        useDate = d;
    }
    return getWeekDays(getWeekRange(nextWeekMonday(useDate)).from);
};
