export const customTheme = {
    siteVariables: {
        // colorScheme: this.state.theme
    },
    componentVariables: {
        //These are component variables that are used in the component styles
        TableRow: {
            headerFontSize: "0.8rem",
            bodyFontSize: "0.8rem",
            cellPadding: "0.1rem 0.1rem",
        },
        Input: {
            colorDisabled: "#C4314B",
        },
        Button: {
            iconSize: "0.1rem",
        },
        SvgIcon: {
            mediumSize: "1.0rem",
        },
        Text: {
            fontSizeMedium: "0.8rem",
        },
    },
    componentStyles: {},
};

export const customMobileTheme = {
    siteVariables: {
        // colorScheme: this.state.theme
    },
    componentVariablesInput: {},
    componentVariables: {
        //These are component variables that are used in the component styles
        TableRow: {
            headerFontSize: "0.5rem",
            cellPadding: "0.1rem 0.1rem",
            bodyFontSize: "0.7rem",
        },
        Input: {
            colorDisabled: "#C4314B",
        },
        Button: {
            iconSize: "0.1rem",
        },
        SvgIcon: {
            mediumSize: "0.9rem",
        },
        Text: {
            fontSizeMedium: "0.7rem",
        },
    },
};

export const mobileProviderStyle = {
    display: "inline-block",
    maxWidth: "100vw",
    minHeight: "100vh",
    padding: "0px",
};
