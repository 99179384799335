import {
    Flex,
    Tooltip,
    Text,
    Button,
    EyeSlashIcon,
    InfoIcon,
} from "@fluentui/react-northstar";
import React from "react";
import { AddProjectDialog } from "../components/AddProjectDialog";
import TimeEntryInput from "../components/TimeEntryInput";
import { IDropdownElement } from "../components/WeekTable";
import * as Constants from "../utils/constants";

const PROJECT_COLUMN_STYLES = {
    minWidth: "100px",
    maxWidth: "100px",
};

const WEEK_TOTAL_HEADER_STYLES = {
    minWidth: "100px",
    maxWidth: "100px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
};

const HIDE_COLUMN_BUTTON_STYLES = {
    minWidth: "50px",
    maxWidth: "50px",
};

const DAY_TOTAL_CELL_STYLES = {
    minWidth: "100px",
    maxWidth: "100px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
};

const GENERATE_SELECT_PROJECT_STYLES = {
    // border: "2px solid red",
    display: "tableCell",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
};

// Generate headers for the week table
export const generateHeaders = (dateArr) => {
    let header: object = {};
    const itemsArr: any[] = [];

    // Name of the project / company
    itemsArr.push({
        content: "PROJECT",
        key: "project",
        styles: PROJECT_COLUMN_STYLES,
    });
    // itemsArr.push({ content: "Project", key: "project" });

    // Dates for the week (mon-sun)
    for (let i = 1; i < 8; i++) {
        const styles = {
            minWidth: "100px",
            maxWidth: "100px",
        };
        const today = Date.UTC(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
        );

        if (today === dateArr[i]) {
            styles["color"] = "#C4314B";
            styles["textDecorationLine"] = "underline";
        }

        const date = new Date(dateArr[i]);

        itemsArr.push({
            // content: dateArr[i].format("ddd DD.MM.YY"),
            content: date.toLocaleDateString("en-GB", {
                weekday: "long",
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
            }),
            // key: dateArr[i].format("dd"),
            key: dateArr[i],
            styles: styles,
        });
    }
    // Total hours header
    itemsArr.push({
        content: "Week Total",
        key: "weekTotal",
        styles: WEEK_TOTAL_HEADER_STYLES,
    });

    // Hide button column header
    itemsArr.push({
        content: "",
        key: "hide",
        truncateContent: true,
        styles: HIDE_COLUMN_BUTTON_STYLES,
    });

    // // Play button column header
    // itemsArr.push({
    //     content: "",
    //     key: "timeEntryInput",
    //     // truncateContent: true,
    //     styles: {
    //         minWidth: "100px",
    //         maxWidth: "100px",
    //     },
    // });

    header = {
        key: "header",
        items: itemsArr,
    };
    return header;
};

export const generateWeekTotalCell = (
    companyName,
    projectName,
    weekTotal,
    projectID
) => {
    return {
        key: `${projectID}-total`,
        content: (
            <Text
                // align="center"
                size="medium"
                weight="semibold"
                color="orange"
                content={weekTotal[projectID]}
            />
        ),
        truncateContent: true,
        styles: WEEK_TOTAL_HEADER_STYLES,
    };
};

// eslint-disable-next-line no-unused-vars
const calculateDayTotal = (day, dayTotal): number => {
    let ret: number = 0;
    if (day in dayTotal) {
        ret = dayTotal[day];
    }
    return ret;
};

export const generateDayTotalCell = (selectedDays, dayTotal, weekTotal) => {
    const row: any[] = [];
    row.push({
        key: `dayTotal_header`,
        content: (
            <div>
                <Text
                    // align="center"
                    // size="medium"
                    // weight="semibold"
                    // color="white"
                    content={"Day Total"}
                />
            </div>
        ),
        styles: PROJECT_COLUMN_STYLES,
    });

    for (let i = 0; i < 7; i++) {
        let color = "green";
        let total: number | string = calculateDayTotal(
            selectedDays[i + 1],
            dayTotal
        );
        if (total < 7) {
            color = "red";
        }
        total = total.toFixed(1);
        row.push({
            key: `dayTotal${i}`,
            content: (
                <div>
                    <Text
                        align="center"
                        size="medium"
                        weight="semibold"
                        color={color}
                        content={total}
                    />
                </div>
            ),
            styles: DAY_TOTAL_CELL_STYLES,
        });
    }

    // Week total cell
    let totalHours: string | number = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, value] of Object.entries(weekTotal)) {
        totalHours += parseFloat(value as string);
    }
    let color = "green";
    if (totalHours < 37.5) {
        color = "red";
    }
    totalHours = totalHours.toFixed(1);
    row.push({
        key: `dayTotal_weekTotal`,
        content: (
            <div>
                <Text
                    align="center"
                    size="medium"
                    weight="semibold"
                    color={color}
                    content={totalHours}
                />
            </div>
        ),
        styles: DAY_TOTAL_CELL_STYLES,
    });
    return row;
};

export const generateFirstCell = (
    companyName,
    projectName,
    projectId,
    isLocked,
    privColor,
    privText,
    disHasHours
) => {
    const styles = PROJECT_COLUMN_STYLES;
    styles["overflow"] = "scroll"; //append default style with overflow: scroll

    return {
        key: projectId,
        content: (
            <div>
                <Flex gap="gap.smaller" column>
                    <Text key={companyName} content={companyName} />
                    <Tooltip
                        trigger={
                            <div>
                                <Text
                                    color={privColor}
                                    content={projectName}
                                    disabled={
                                        isLocked &&
                                        projectId !==
                                            Constants.DEFAULT_PROJECT_ID
                                    }
                                    // truncated
                                    // size="small"
                                />
                            </div>
                        }
                        content={privText}
                    />
                </Flex>
            </div>
        ),
        hashours: disHasHours.toString(), // With this flag we control which projects are to be seen. (Automatically shown if hours)
        styles: styles,
    };
};

// Generate Trash Cell
export const generateHideCell = (
    projectName,
    projectId,
    disHasHours,
    handleHide
) => {
    const tooltip = disHasHours ? (
        <div>
            <div>Hours applied,</div>
            <div>cannot hide this week</div>
        </div>
    ) : projectId === Constants.DEFAULT_PROJECT_ID ? (
        `Cannot hide default project`
    ) : (
        `Hide project: ${projectName}`
    );
    return {
        key: `${projectId}-hide`,
        content: (
            <div>
                <Flex gap="gap.smaller">
                    <Tooltip
                        trigger={
                            <Button
                                icon={
                                    <EyeSlashIcon
                                        disabled={
                                            disHasHours ||
                                            projectId ===
                                                Constants.DEFAULT_PROJECT_ID
                                        }
                                        style={{
                                            color: "#C4314B",
                                        }}
                                    />
                                }
                                text
                                iconOnly
                                onClick={handleHide(projectId, disHasHours)}
                            />
                        }
                        content={tooltip}
                    />
                </Flex>
            </div>
        ),
        styles: HIDE_COLUMN_BUTTON_STYLES,
    };
};

// Generate Trash Cell
export const generateBudgetCell = (
    companyName,
    projectName,
    projectId,
    budget
) => {
    const tooltip = budget ? (
        <div>
            <div>Budget total: {budget.total} h</div>
            <div>Budget used: {budget.used ? budget.used : 0} h</div>
            <div>Budget left: {budget.total - budget.used} h</div>
        </div>
    ) : (
        `No budget set to this project`
    );
    return {
        key: `${projectId}-budget`,
        content: (
            <div>
                <Flex gap="gap.smaller">
                    <Tooltip
                        trigger={
                            <Button
                                icon={
                                    <InfoIcon
                                        disabled={!budget}
                                        style={{
                                            color: "green",
                                        }}
                                    />
                                }
                                text
                                iconOnly
                                // onClick={}
                            />
                        }
                        content={tooltip}
                    />
                </Flex>
            </div>
        ),
        styles: HIDE_COLUMN_BUTTON_STYLES,
    };
};

export const generateSelectProject = (
    dropdownArr: IDropdownElement[] | null,
    handleSelectedProject,
    isAdmin,
    decodedTok,
    handleNewProject,
    jwtoken
) => {
    return [
        {
            key: `Select_Project`,
            content: (
                <AddProjectDialog
                    dropdownArr={dropdownArr}
                    isAdmin={isAdmin}
                    decodedToken={decodedTok}
                    // passVariable={handleVariables}
                    key={"addProjectDialog"}
                    passChildData={handleNewProject}
                    token={jwtoken}
                    handleSelectedProject={handleSelectedProject}
                />
            ),
            // truncateContent: true,
            styles: GENERATE_SELECT_PROJECT_STYLES,
        },
    ];
};

//Currently not used
export const generateTimeEntryInput = (
    projectID,
    company,
    project,
    handleDuration
) => {
    return {
        key: `${projectID}-timeEntryInput`,
        content: (
            <TimeEntryInput
                projectID={projectID}
                company={company}
                project={project}
                addDuration={handleDuration}
            />
        ),
        styles: {
            padding: 0,
        },
    };
};
