export const uploadFileToOneDrive = async (
    token: string,
    foldername: string,
    filename: string,
    data: BodyInit
) => {
    console.log(`Write file: ${filename} into: ${filename}`);
    // console.log(`Data: ${data}`);
    return fetch(
        `https://graph.microsoft.com/v1.0/me/drive/root:/${foldername}/${filename}:/content`,
        {
            method: "PUT",
            headers: {
                "Content-Type":
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                authorization: "bearer " + token,
            },
            body: data,
            mode: "cors",
            cache: "default",
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw `Error ${response.status}: ${response.statusText} : ${response.body}`;
            }
        })
        .then((drive) => {
            // console.log(JSON.stringify(drive, undefined, 4), "pre");
            return drive;
        });
};
