import * as React from "react";
import { Loader } from "@fluentui/react-northstar";

type ILoaderProps = {
    msg: string;
};

export const CustomLoader = (props: ILoaderProps) => {
    return (
        <div
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}
        >
            <Loader label={props.msg} />
        </div>
    );
};

export default CustomLoader;
