import { User } from "../types/types";
// import { process.env.REACT_APP_APIURL } from "../utils/constants";
const getHeader = async (token) => {
    return {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "x-frontend-version": `${process.env.REACT_APP_VERSION}`,
    };
};

/**
 * Handle errors from backend
 * @param response Response from backend
 * @returns Promise with response
 */
function handleErrors(response): any {
    return new Promise<void>((resolve, reject) => {
        if (!response.ok) {
            if (response.status === 409) {
                console.error(`Frontend and backend version mismatch!`);
            }
            return reject(response);
        }

        if (response.status === 204) {
            return resolve();
        }

        return response.json().then(resolve).catch(resolve);
    });
}

/** Fetch serverside token (AAD) */
export const getServerSideToken = async (token: string, tid: string) => {
    return fetch(`${process.env.REACT_APP_APIURL}/auth/aadToken`, {
        method: "post",
        headers: await getHeader(token),
        body: JSON.stringify({
            tid: tid,
            token: token,
        }),
        mode: "cors",
        cache: "default",
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch serverside token (AAD) */
export const getServerSideVersion = async () => {
    return fetch(`${process.env.REACT_APP_APIURL}/versionNumber`, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        mode: "cors",
        cache: "default",
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch hours from DB and save file into oneDrive */
export const fetchHours = async (token, body) => {
    return fetch(`${process.env.REACT_APP_APIURL}/project/reports/hours`, {
        method: "post",
        headers: await getHeader(token),
        body: JSON.stringify(body),
        mode: "cors",
        cache: "default",
    }).then((response) => {
        if (response.status !== 200) {
            return response.json();
        }
        return response.arrayBuffer();
    });
};

/** Fetch yearly total for all Offcode users & companies from DB and save file into oneDrive */
export const fetchYearlyTotalOffcode = async (token, body): Promise<any> => {
    return fetch(
        `${process.env.REACT_APP_APIURL}/project/reports/yearlyTotalOffcode`,
        {
            method: "post",
            headers: await getHeader(token),
            body: JSON.stringify(body),
            mode: "cors",
            cache: "default",
        }
    ).then((response) => {
        if (response.status !== 200) {
            return response.json();
        }
        return response.arrayBuffer();
    });
};

/** Fetch yearly total from DB and save file into oneDrive */
export const fetchYearlyTotal = async (token, body): Promise<any> => {
    return fetch(
        `${process.env.REACT_APP_APIURL}/project/reports/yearlyTotal`,
        {
            method: "post",
            headers: await getHeader(token),
            body: JSON.stringify(body),
            mode: "cors",
            cache: "default",
        }
    ).then((response) => {
        if (response.status !== 200) {
            return response.json();
        }
        return response.arrayBuffer();
    });
};

/** Fetch companies / projects that has time entries for current user  */
export const fetchHasHours = async (token, body) => {
    return fetch(`${process.env.REACT_APP_APIURL}/project/hasHours`, {
        method: "post",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch projects based on company id */
export const getProjects = async (token, id) => {
    return fetch(`${process.env.REACT_APP_APIURL}/project/projects/${id}`, {
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch all companies */
export const getCompanies = async (token) => {
    return fetch(`${process.env.REACT_APP_APIURL}/company`, {
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Create company */
export const createCompany = async (token, body) => {
    return fetch(`${process.env.REACT_APP_APIURL}/company`, {
        method: "post",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch company settings based on company name */
export const getCompanySettings = async (token, name) => {
    return fetch(`${process.env.REACT_APP_APIURL}/company/${name}`, {
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch Users */
export const getUsers = async (token) => {
    return fetch(`${process.env.REACT_APP_APIURL}/user/`, {
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Update User app_settings */
export const updateUserAppSettings = async (token, body): Promise<User> => {
    // console.log(body);
    return fetch(`${process.env.REACT_APP_APIURL}/user/app_settings`, {
        method: "put",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Set new user */
export const setNewUser = async (token, body) => {
    return fetch(`${process.env.REACT_APP_APIURL}/user`, {
        method: "post",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch User */
export const getUser = async (token, oid): Promise<User> => {
    return fetch(`${process.env.REACT_APP_APIURL}/user/` + oid, {
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
    // .then((response) => response.json())
    // .then((user: User) => {
    //     return user;
    // })
    // .catch((err) => {
    //    throw err;
    // });
};

/** Get weektotal */
export const getWeekTotal = async (token, body) => {
    return fetch(`${process.env.REACT_APP_APIURL}/timeEntry/weekTotal`, {
        method: "post",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Get timeEntry (from - to) */
export const getTimeEntriesBetween = async (token, date) => {
    return fetch(
        `${process.env.REACT_APP_APIURL}/timeEntry/${date.from}/${date.to}`,
        {
            method: "get",
            headers: await getHeader(token),
        }
    )
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Get hours (from - to) */
export const getHoursBetween = async (token, date) => {
    return fetch(
        `${process.env.REACT_APP_APIURL}/timeEntry/hours/${date.from}/${date.to}`,
        {
            method: "get",
            headers: await getHeader(token),
        }
    )
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Submit time entry insto DB */
export const submitTimeEntry = async (token, body) => {
    return fetch(`${process.env.REACT_APP_APIURL}/timeEntry`, {
        method: "post",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Update existing time entry */
export const updateTimeEntry = async (token, body, id) => {
    return fetch(`${process.env.REACT_APP_APIURL}/timeEntry/` + id, {
        method: "put",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Update existing time entry */
export const updateDescription = async (token, body, id) => {
    return fetch(
        `${process.env.REACT_APP_APIURL}/timeEntry/description/` + id,
        {
            method: "put",
            headers: await getHeader(token),
            body: JSON.stringify(body),
        }
    )
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Delete timeEntry */
export const deleteTimeEntry = async (token, id, from) => {
    return fetch(`${process.env.REACT_APP_APIURL}/timeEntry/${id}/${from}`, {
        method: "delete",
        headers: await getHeader(token),
        // body: JSON.stringify({}),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Get projects (for user) */
export const fetchProjects = async (token) => {
    return fetch(`${process.env.REACT_APP_APIURL}/project`, {
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Get projects based on company_id & projectName */
export const fetchProject = async (token, companyID, projectName) => {
    return fetch(
        `${process.env.REACT_APP_APIURL}/project/byID/${companyID}/${projectName}`,
        {
            headers: await getHeader(token),
        }
    )
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Delete project based on id */
export const deleteProject = async (token, id) => {
    return fetch(`${process.env.REACT_APP_APIURL}/project/${id}`, {
        method: "delete",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

//NOTE TO MYSELF: USE THIS FORMAT FOR ALL CALLS!
/** Fetch project setting based on project name*/
export const getProjectSettings = async (token, name) => {
    return await fetch(
        `${process.env.REACT_APP_APIURL}/project/byName/${name}`,
        {
            headers: await getHeader(token),
        }
    )
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

//Get project by id
export const getProjectById = async (token, id) => {
    return await fetch(`${process.env.REACT_APP_APIURL}/project/${id}`, {
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Update existing project settings */
export const updateProjectSettings = async (token, body, id) => {
    return await fetch(`${process.env.REACT_APP_APIURL}/project/` + id, {
        method: "put",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Create project */
export const createProject = async (token, body) => {
    return fetch(`${process.env.REACT_APP_APIURL}/project`, {
        method: "post",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/**Import hours from file */
export const importHours = async (token, body) => {
    return fetch(`${process.env.REACT_APP_APIURL}/company/importTimeTable`, {
        method: "post",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};
