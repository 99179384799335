import React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import "../utils/css/App.css";
import jwt_decode from "jwt-decode";
import * as Constants from "../utils/constants";
import * as Backend from "../actions/backendCalls";
import * as OneDrive from "../actions/oneDrive";
import moment from "moment";

import {
    Button,
    Dropdown,
    // Flex,
    Checkbox,
    Datepicker,
    mergeThemes,
    Provider,
    teamsDarkTheme,
    teamsTheme,
    Popup,
    Loader,
    Text,
    Flex,
} from "@fluentui/react-northstar";
import { reject } from "lodash";
import { IReportsTab, ReportType, REPORT_TYPES } from "./IReportsTab";
import { YearDropdown } from "./YearDropdown";
import CustomLoader from "./CustomLoader";

/**
 * The 'PersonalTab' component renders the main tab content
 * of your app.
 */
class ReportsTab extends React.Component {
    state: IReportsTab;
    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.state = {
            theme: "",
            context: {},
            jwtoken: "",
            decodedToken: {},
            isMobile: window.innerWidth >= Constants.MOBILEWIDTH ? false : true,
            data: [],
            projectsList: [],
            userList: [],
            projectsData: [""],
            projectCompanyID: [],
            usersData: [""],
            companiesList: [],
            companiesData: [""],
            isDisabled: true,
            isCompanyDisabled: true,
            startDate: null,
            endDate: null,
            selectedProjects: [],
            selectedProjectsName: [],
            selectedUsersNames: [],
            selectedUsersIDs: [],
            selectedCompany: undefined,
            selectedCompanyName: undefined,
            companySelected: false,
            debug: "",
            popup: <Loader size="small" label="Saving..." />,
            serverSideToken: null,
            selectedDateStart: undefined,
            selectedDateEnd: undefined,
            getAllChecked: false,
            reportType: undefined,
            ready: false,
            datePickerKey: "datePicker",
            projectTypeSelected: false,
            year: new Date().getFullYear(),
            userSelected: false,
            user: {
                id: "",
                app_settings: {
                    type: {},
                    visible_projects: [""],
                    properties: {
                        theme: "",
                    },
                    default_project: {
                        company_name: "",
                        company_id: "",
                        project_name: "",
                        project_id: "",
                        default_project_string: "",
                    },
                },
                full_name: "",
                email: "",
                oid: "",
                disabled: false,
                is_admin: false,
                is_project_manager: false,
                status_updated: 0,
            },
            errorMsg: "",
            error: false,
        };
    }

    updateMobileState = () => {
        window.innerWidth >= Constants.MOBILEWIDTH
            ? this.setState({ isMobile: false })
            : this.setState({ isMobile: true });
    };

    componentDidUpdate() {
        window.addEventListener("resize", this.updateMobileState);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateMobileState);
    }

    //React lifecycle method that gets called once a component has finished mounting
    //Learn more: https://reactjs.org/docs/react-component.html#componentdidmount
    async componentDidMount() {
        const res = await Backend.getServerSideVersion();
        console.log("Backend version: " + res.version);
        console.log("Frontend version: " + process.env.REACT_APP_VERSION);

        // Get the user context from Teams and set it in the state
        microsoftTeams.getContext((context: microsoftTeams.Context) => {
            this.setState({
                context: context,
            });
        });

        microsoftTeams.registerOnThemeChangeHandler((theme) => {
            if (theme !== this.state.theme) {
                this.setState({ theme });
            }
        });
        // Get Auth token from AD
        const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest =
            {
                successCallback: async (result: string) => {
                    try {
                        const decodedToken = jwt_decode(result);
                        this.setState({
                            jwtoken: result,
                            decodedToken: decodedToken,
                        });
                        const resp = await Backend.getServerSideToken(
                            result,
                            this.state.context.tid
                        );
                        this.setState({ serverSideToken: resp });
                        await this.getUser();
                        await this.getUsers();
                        await this.getCompanies();
                    } catch (err: any) {
                        if (err.status === 409) {
                            this.setState({
                                error: true,
                                errorMsg:
                                    "Frontend and backend are not in sync. Please wait a few minutes and try again. If that doesn't help please contact Toni.K or Levi",
                            });
                            return;
                        }
                        console.error(err);
                        this.setState({
                            error: true,
                            errorMsg: err,
                        });
                    }
                },
                failureCallback: function (error: string) {
                    reject("Failure while getting client side token: " + error);
                },
                // resources: ["https://graph.microsoft.com"],
            };
        microsoftTeams.authentication.getAuthToken(authTokenRequest);

        window.addEventListener("onload", this.updateMobileState);
        microsoftTeams.appInitialization.notifySuccess();
    }

    getUser = async () => {
        this.setState({
            ready: false,
        });
        if (this.state.jwtoken !== "") {
            const user = await Backend.getUser(
                this.state.jwtoken,
                this.state.decodedToken.oid
            );
            this.setState({
                user: user,
                userSelected: user.is_admin ? false : true,
            });
        }
    };

    getUsers = async () => {
        if (this.state.jwtoken !== "") {
            const listOfUserNames: string[] = [];
            const users = await Backend.getUsers(this.state.jwtoken);
            users.forEach((user) => {
                listOfUserNames.push(user.full_name);
            });

            if (listOfUserNames === undefined || listOfUserNames.length == 0) {
                console.warn("No Users found");
            }

            this.setState({
                userList: listOfUserNames,
                usersData: users,
            });
        }
    };

    // eslint-disable-next-line no-unused-vars
    createReportWorkBook = async (serversideToken, data, type) => {
        try {
            const date = new Date();
            const year = date.getFullYear();
            const timestamp = moment(date).format("YYYYMMDD-HHmmss");
            const folderName = `Time Tracker/${this.state.user.full_name}/${year}`;
            let filename = "";
            let yearlyString = "";

            switch (type) {
                case ReportType.TIME_ENTRIES:
                    break;
                case ReportType.YEAR_REPORT:
                    yearlyString = "YEARLY_REPORT_";
                    break;
                default:
                    break;
            }

            if (this.state.selectedProjectsName.length > 0) {
                if (this.state.selectedUsersNames.length > 0) {
                    filename = `${this.state.selectedCompanyName}_${this.state.selectedProjectsName}_${this.state.selectedUsersNames}_${timestamp}.xlsx`;
                } else {
                    filename = `${this.state.selectedCompanyName}_${this.state.selectedProjectsName}_${timestamp}.xlsx`;
                }
            } else {
                if (this.state.selectedUsersNames.length > 0) {
                    filename = `${yearlyString}${this.state.selectedCompanyName}_all_projects_${this.state.selectedUsersNames}_${timestamp}.xlsx`;
                } else {
                    filename = `${yearlyString}${this.state.selectedCompanyName}_all_projects_and_users_${timestamp}.xlsx`;
                }
            }

            const response = await OneDrive.uploadFileToOneDrive(
                serversideToken,
                folderName,
                filename,
                data
            );
            const fullPath = response.webUrl;
            const simplePath = response.parentReference.path.split(":")[1];
            console.log(fullPath);
            console.log(filename);
            const popup = (
                <p>
                    <Text color="green" weight="bold">
                        Done!
                    </Text>
                    <br />
                    File: <b>{filename}</b> can be found from:
                    <br />
                    <b>{simplePath}</b>
                    <br />
                    <a
                        target={"new"}
                        style={{ color: "orange" }}
                        href={fullPath}
                    >
                        Link to the folder
                    </a>
                </p>
            );
            return popup;
        } catch (error) {
            console.error();
            return error;
        }
    };

    getReport = async () => {
        try {
            const body = {
                company: this.state.selectedCompany,
                projects: this.state.selectedProjects,
                from: this.state.startDate,
                to: this.state.endDate,
                getAll: this.state.getAllChecked,
                users: this.state.selectedUsersIDs,
            };
            let resp;
            if (this.state.jwtoken !== "" && this.state.serverSideToken) {
                switch (this.state.reportType) {
                    case ReportType.TIME_ENTRIES:
                        {
                            console.log(body);

                            resp = await Backend.fetchHours(
                                this.state.jwtoken,
                                body
                            ); //Creates time entries report in backend (returs stream)
                        }
                        break;

                    case ReportType.YEAR_REPORT:
                        {
                            /** Get firt and last date of the current year */
                            const firstDay = new Date(this.state.year, 0, 1);
                            const lastDay = new Date(this.state.year, 11, 31);

                            body.from = firstDay.valueOf();
                            body.to = lastDay.valueOf();

                            /** This flag determines if we want to have all user information or just company specific infomation */
                            if (
                                this.state.selectedCompanyName ===
                                "==OFFCODE_USERS=="
                            ) {
                                resp = await Backend.fetchYearlyTotalOffcode(
                                    this.state.jwtoken,
                                    body
                                );
                            } else {
                                resp = await Backend.fetchYearlyTotal(
                                    this.state.jwtoken,
                                    body
                                ); // This call uses backend to create the report (faster)
                            }
                        }
                        break;

                    default:
                        break;
                }

                if (resp.error) throw resp.error;
                const respPopup = await this.createReportWorkBook(
                    this.state.serverSideToken,
                    resp,
                    this.state.reportType
                );
                this.setState({ popup: respPopup });
            } else {
                throw "Token missing!";
            }
        } catch (error) {
            console.error(error);
            this.setState({
                popup: `${error}`,
            });
        }
    };

    getProjects = async (selectedCompanyID) => {
        const listOfProjects: any = [];
        let disabled = false;
        try {
            const projects = await Backend.getProjects(
                this.state.jwtoken,
                selectedCompanyID
            );
            projects.forEach((project) => {
                listOfProjects.push(project.project_name);
            });

            if (listOfProjects === undefined || listOfProjects.length == 0) {
                console.log("No projects for this company available");
                disabled = true;
                this.setState({ companySelected: false });
            }

            this.setState({
                projectsList: listOfProjects,
                projectsData: projects,
                selectedCompany: selectedCompanyID,
                isDisabled: disabled,
            });
        } catch (error) {
            console.error(error);
        }
    };

    getCompanies = async () => {
        const listOfCompanies: any = [];
        const listOfProjects: any = [];
        const body = {};
        // let disabled = false;
        let companyDisabled = false;
        this.setState({
            ready: false,
        });

        const res = await Backend.fetchHasHours(this.state.jwtoken, body);
        res.companies.forEach((company) => {
            listOfCompanies.push(company);
        });

        res.projects.forEach((project) => {
            listOfProjects.push(project);
        });

        /**Check that companies is not empty */
        if (listOfCompanies === undefined || listOfCompanies.length == 0) {
            console.log("No companies found");
            companyDisabled = true;
            this.setState({ companySelected: false });
        } else {
            companyDisabled = false;
        }
        this.setState({
            companiesList: listOfCompanies.sort(),
            projectCompanyID: res.projectCompanyID,
            // projectsList: listOfProjects,
            // isDisabled: disabled,
            isCompanyDisabled: companyDisabled,
            ready: true,
        });
    };

    getCompanyID = (companyName: string) => {
        Backend.getCompanySettings(this.state.jwtoken, companyName)
            .then((response) => {
                // this.getProjects(response[0].id);
                const projects: string[] = [];

                this.state.projectCompanyID.forEach((e) => {
                    if (e["company_id"] === response[0].id) {
                        projects.push(e["project_name"]);
                    }
                });

                this.setState({
                    selectedCompany: response[0].id,
                    projectsList: projects,
                    isDisabled: false,
                });
            })
            .catch((err) => console.error(err));
    };

    handleCompanyChange = (e, d) => {
        if (d.value) {
            this.setState({ selectedCompanyName: d.value });
            if (d.value !== "==OFFCODE_USERS==" && d.value !== "== ALL ==") {
                this.getCompanyID(d.value);
            } else {
                this.setState({ selectedCompany: undefined }); // Clear selected company
            }
            this.setState({ companySelected: true });
        } else {
            this.clearFields();
        }
    };

    handleProjectChange = (e, d: any = []) => {
        const pIDArr: string[] = [];
        for (const val in d.value) {
            this.state.projectCompanyID.forEach((e) => {
                if (e["project_name"] === d.value[val]) {
                    pIDArr.push(e["project_id"]);
                }
            });
        }

        this.setState({
            selectedProjectsName: d.value,
            selectedProjects: pIDArr,
        });
    };

    handleUserChange = (e, d: any = []) => {
        const uIDArr: string[] = [];

        /** This is for multiselect */
        // for (const val in d.value) {
        //     for (const user in this.state.usersData) {
        //         if (this.state.usersData[user].full_name === d.value[val]) {
        //             uIDArr.push(this.state.usersData[user].id);
        //         }
        //     }
        // }

        if (d.value === null) d.value = undefined;

        for (const user in this.state.usersData) {
            if (this.state.usersData[user].full_name === d.value) {
                uIDArr.push(this.state.usersData[user].id);
            }
        }

        this.setState({
            selectedUsersNames: d.value,
            selectedUsersIDs: uIDArr,
            userSelected: d.value ? true : false,
        });
    };

    handleReportTypeChange = (e, d: any = []) => {
        if (d.value === null) d.value = undefined;

        /** ==OFFCODE_USERS== are only user to get yearly report. Remove it otherwise*/
        if (
            this.state.companiesList[0] === "==OFFCODE_USERS==" ||
            this.state.companiesList[0] === "== ALL =="
        ) {
            const modifiedCompanyList = this.state.companiesList;
            modifiedCompanyList.shift();
            this.setState({
                companiesList: modifiedCompanyList,
            });
        }

        this.setState({
            reportType: d.value,
            projectTypeSelected: d.value ? true : false,
            userSelected: true,
        });

        const modifiedCompanyList = this.state.companiesList;

        if (d.value === ReportType.YEAR_REPORT) {
            modifiedCompanyList.unshift("==OFFCODE_USERS==");
        }

        if (d.value === ReportType.TIME_ENTRIES) {
            modifiedCompanyList.unshift("== ALL ==");
        }

        this.setState({
            selectedDateStart: undefined,
            selectedDateEnd: undefined,
            datePickerKey: `datepickerKey${new Date()}`, //This is the way to trigger datepicker re-render
            userSelected: true,
            companiesList: modifiedCompanyList,
        });
    };

    handleStartDate = (e, data) => {
        this.setState({
            startDate: data.value?.valueOf(),
            selectedDateStart: data.value,
        });
    };

    handleEndDate = (e, data) => {
        this.setState({
            endDate: data.value.valueOf() + (23 * 60 + 59) * 60 * 1000,
            selectedDateEnd: data.value,
        });
    };

    clearFields = () => {
        this.setState({
            popup: "",
            selectedCompany: [],
            selectedCompanyName: [],
            selectedProjects: [],
            selectedProjectsName: [],
            selectedUsersIDs: [],
            selectedUsersNames: [],
            companySelected: false,
            isDisabled: true,
            startDate: null,
            endDate: null,
            selectedDateStart: null,
            selectedDateEnd: null,
            userSelected: false,
        });
    };

    handleYearChange = (year: number) => {
        console.log(`year: ${year}`);
        this.setState({
            year: year,
        });
    };

    render() {
        // const popperRef = React.useRef() as any;
        let newTheme;
        // Default theme
        let setTheme = {
            siteconstiables: {},
            componentconstiables: {},
            componentStyles: {},
        };

        let theme = this.state.theme; // theme (dark, light, highres)
        if (!theme) {
            theme = this.state.context["theme"];
        }
        if (theme === "default" || theme === "light") {
            newTheme = teamsTheme;
        } else if (theme === "dark" || theme === undefined) {
            newTheme = teamsDarkTheme;
        }

        // theme for mobile mode
        if (this.state.isMobile) {
            setTheme = {
                siteconstiables: {},
                componentconstiables: {},
                componentStyles: {},
            };
        }

        return (
            <Provider theme={mergeThemes(newTheme, setTheme)}>
                {!this.state.error ? (
                    <div>
                        {this.state.context["theme"] && this.state.ready ? (
                            <div
                                style={{
                                    paddingLeft: 50,
                                    paddingRight: 50,
                                    paddingBottom: 50,
                                    display: "inlineBlock",
                                }}
                            >
                                <Flex gap="gap.large">
                                    <Flex.Item size="size.half">
                                        <div>
                                            <h3>Download project reports</h3>
                                            <p></p>
                                            <div style={{ maxWidth: "250px" }}>
                                                <Dropdown
                                                    // disabled={this.state.projectTypeSelected}
                                                    items={REPORT_TYPES}
                                                    placeholder="Select report type"
                                                    checkable
                                                    clearable
                                                    // multiple
                                                    fluid
                                                    value={
                                                        this.state.reportType
                                                    }
                                                    getA11ySelectionMessage={{
                                                        onAdd: (item) =>
                                                            `${item} has been selected.`,
                                                    }}
                                                    onChange={
                                                        this
                                                            .handleReportTypeChange
                                                    }
                                                />
                                                {this.state
                                                    .projectTypeSelected ? (
                                                    <>
                                                        <p></p>
                                                        <Dropdown
                                                            disabled={
                                                                this.state
                                                                    .isCompanyDisabled
                                                            }
                                                            items={
                                                                this.state
                                                                    .companiesList
                                                            }
                                                            placeholder="Select company"
                                                            // checkable
                                                            clearable
                                                            fluid
                                                            getA11ySelectionMessage={{
                                                                onAdd: (item) =>
                                                                    `${item} has been selected.`,
                                                            }}
                                                            onChange={
                                                                this
                                                                    .handleCompanyChange
                                                            }
                                                            value={
                                                                this.state
                                                                    .selectedCompanyName
                                                            }
                                                        />
                                                        <p></p>
                                                        {this.state
                                                            .reportType ===
                                                        ReportType.YEAR_REPORT ? (
                                                            <>
                                                                <p></p>
                                                                <YearDropdown
                                                                    projectChangeParentHandler={
                                                                        this
                                                                            .handleYearChange
                                                                    }
                                                                />
                                                                <p></p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Dropdown
                                                                    disabled={
                                                                        this
                                                                            .state
                                                                            .isDisabled
                                                                    }
                                                                    items={
                                                                        this
                                                                            .state
                                                                            .projectsList
                                                                    }
                                                                    placeholder="Select project"
                                                                    checkable
                                                                    multiple
                                                                    fluid
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .selectedProjectsName
                                                                    }
                                                                    getA11ySelectionMessage={{
                                                                        onAdd: (
                                                                            item
                                                                        ) =>
                                                                            `${item} has been selected.`,
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .handleProjectChange
                                                                    }
                                                                />
                                                                <p></p>
                                                                <Datepicker
                                                                    inputPlaceholder="Select start date"
                                                                    onDateChange={
                                                                        this
                                                                            .handleStartDate
                                                                    }
                                                                    selectedDate={
                                                                        this
                                                                            .state
                                                                            .selectedDateStart
                                                                    }
                                                                    disabled={
                                                                        !this
                                                                            .state
                                                                            .companySelected
                                                                    }
                                                                    input={{
                                                                        clearable:
                                                                            true,
                                                                    }}
                                                                    key={`start${this.state.datePickerKey}`}
                                                                />
                                                                <p></p>
                                                                <Datepicker
                                                                    inputPlaceholder="Select end date"
                                                                    onDateChange={
                                                                        this
                                                                            .handleEndDate
                                                                    }
                                                                    selectedDate={
                                                                        this
                                                                            .state
                                                                            .selectedDateEnd
                                                                    }
                                                                    disabled={
                                                                        !this
                                                                            .state
                                                                            .companySelected
                                                                    }
                                                                    input={{
                                                                        clearable:
                                                                            true,
                                                                    }}
                                                                    key={`end${this.state.datePickerKey}`}
                                                                />

                                                                {this.state.user
                                                                    .is_admin ||
                                                                this.state.user
                                                                    .is_project_manager ? (
                                                                    <>
                                                                        <p></p>
                                                                        <Checkbox
                                                                            disabled={
                                                                                !this
                                                                                    .state
                                                                                    .companySelected
                                                                            }
                                                                            label="Check to collect everybody"
                                                                            toggle
                                                                            onChange={(
                                                                                e,
                                                                                d
                                                                            ) => {
                                                                                if (
                                                                                    d
                                                                                ) {
                                                                                    this.setState(
                                                                                        {
                                                                                            getAllChecked:
                                                                                                d.checked,
                                                                                            selectedUsersNames:
                                                                                                [],
                                                                                            selectedUsersIDs:
                                                                                                [],
                                                                                            userSelected:
                                                                                                d.checked,
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                        <p></p>
                                                                        <Dropdown
                                                                            disabled={
                                                                                !this
                                                                                    .state
                                                                                    .companySelected ||
                                                                                this
                                                                                    .state
                                                                                    .getAllChecked
                                                                            }
                                                                            items={
                                                                                this
                                                                                    .state
                                                                                    .userList
                                                                            }
                                                                            placeholder="Select user"
                                                                            checkable
                                                                            clearable
                                                                            // multiple
                                                                            fluid
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .selectedUsersNames
                                                                            }
                                                                            getA11ySelectionMessage={{
                                                                                onAdd: (
                                                                                    item
                                                                                ) =>
                                                                                    `${item} has been selected.`,
                                                                            }}
                                                                            onChange={
                                                                                this
                                                                                    .handleUserChange
                                                                            }
                                                                        />
                                                                        <p></p>
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </>
                                                ) : null}
                                                <p></p>
                                            </div>
                                            <Popup
                                                position-align="above-start"
                                                content={this.state.popup}
                                                trigger={
                                                    <Button
                                                        disabled={
                                                            !this.state
                                                                .companySelected ||
                                                            !this.state
                                                                .reportType ||
                                                            !this.state
                                                                .userSelected
                                                        }
                                                        onClick={this.getReport}
                                                        content="Download"
                                                    />
                                                }
                                                onOpenChange={(e, d) => {
                                                    if (d && !d.open) {
                                                        // this.clearFields();
                                                        this.setState({
                                                            popup: "",
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Flex.Item>
                                </Flex>
                            </div>
                        ) : (
                            <CustomLoader msg="Fetching data..." />
                        )}
                    </div>
                ) : (
                    <div
                        style={{
                            minHeight: "100vh",
                            minWidth: "100vw",
                        }}
                    >
                        <Text
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                            weight="bold"
                            size="large"
                            content={this.state.errorMsg}
                        />
                    </div>
                )}
            </Provider>
        );
    }
}
export default ReportsTab;
