import { Dropdown, Flex /* Text */ } from "@fluentui/react-northstar";
import React from "react";
import * as Backend from "../actions/backendCalls";
import { ConfigureProjectDialog } from "./ConfigureProjectDialog";

interface IState {
    isSelected: boolean;
    projectsData: any[];
    projectsList: any[];
    isDisabled: boolean;
    selectedProjectName: string | undefined;
    projectSettings: any[];
}
interface IProjectSettings {
    jwtoken: string;
    companiesList: any[];
    companiesData: any[];
    decodedToken: string;
    admin: boolean;
    manager: boolean;
}

export default class ProjectSettings extends React.Component<IProjectSettings> {
    state: IState;
    constructor(props: IProjectSettings) {
        super(props);
        this.state = {
            isSelected: false,
            isDisabled: true,
            projectsData: [""],
            projectsList: [],
            selectedProjectName: undefined,
            projectSettings: [],
        };
    }

    handleStartDate = (_e, data) => {
        // console.log(data.value.valueOf());
        this.setState({
            startDate: data.value.valueOf(),
        });
    };

    handleEndDate = (_e, data) => {
        this.setState({
            endDate: data.value.valueOf() + (23 * 60 + 59) * 60 * 1000,
        });
    };

    handleOnClick = (/* _e,_data */) => {
        console.log("SAVE");
    };

    handleProjectChange = (e, d) => {
        this.setState({
            isSelected: d.value ? true : false,
            selectedProjectName: d.value,
        });
    };

    handleCompanyChange = (e, d) => {
        if (d.value) {
            this.getCompanyID(d.value);
        } else {
            this.setState({ isDisabled: true });
        }
    };

    getCompanyID = async (companyName: string) => {
        try {
            const company = await Backend.getCompanySettings(
                this.props.jwtoken,
                companyName
            );
            this.getProjects(company[0].id);
        } catch (error) {
            console.error(error);
        }
    };

    getProjects = (selectedCompanyID) => {
        Backend.getProjects(this.props.jwtoken, selectedCompanyID)
            .then((projects) => {
                const listOfProjects: any = [];
                let disabled = false;

                projects.forEach((project) => {
                    listOfProjects.push(project.project_name);
                });
                if (
                    listOfProjects === undefined ||
                    listOfProjects.length == 0
                ) {
                    console.log("No projects for this company available");
                    disabled = true;
                }
                // console.log(projects);
                this.setState({
                    projectsList: listOfProjects,
                    projectsData: projects,
                    isDisabled: disabled,
                });
            })
            .catch((err) => console.log(err));
    };

    handleCompanyEdit = () => {
        console.log("EDIT COMAPNY");
    };

    setEnabled = (enabled: boolean) => {
        this.setState({
            isDisabled: !enabled,
        });
    };

    // handleProjectEdit = async () =>{
    //     console.log("EDIT PROJECT");
    //     try {
    //         const projectSettings = await Backend.getProjectSettings(this.props.jwtoken, this.state.selectedProjectName);
    //         this.setState({
    //             projectSettings: projectSettings,
    //         })
    //         console.log(projectSettings);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    render() {
        return (
            <>
                {/* <Text color={"red"} weight="bold" content={"<ADMINS ONLY> Change project settings"} /> */}
                <div style={{ maxWidth: "500px" }}>
                    <Flex gap="gap.small">
                        <Dropdown
                            disabled={!this.props.admin && !this.props.manager}
                            items={this.props.companiesList}
                            placeholder="Select company"
                            checkable
                            clearable
                            fluid
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onChange={this.handleCompanyChange}
                        />
                        {/* <Button
                            disabledFocusable={this.state.isDisabled}
                            key="editCompanyNameButton"
                            icon={<EditIcon />}
                            id="1"
                            onClick={this.handleCompanyEdit}
                            disabled={this.state.isDisabled}
                            content="Edit"
                        /> */}
                    </Flex>
                    <p></p>
                    <Flex gap="gap.small">
                        <Dropdown
                            disabled={this.state.isDisabled}
                            items={this.state.projectsList}
                            placeholder="Select project"
                            checkable
                            clearable
                            fluid
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onChange={this.handleProjectChange}
                        />
                        <ConfigureProjectDialog
                            jwtoken={this.props.jwtoken}
                            projectName={this.state.selectedProjectName}
                            projectId={
                                this.state.projectsData.find(
                                    (project) =>
                                        project.project_name ===
                                        this.state.selectedProjectName
                                )?.id
                            }
                            selected={this.state.isSelected}
                            decodedToken={this.props.decodedToken}
                            admin={this.props.admin}
                            manager={this.props.manager}
                        />
                    </Flex>
                </div>
            </>
        );
    }
}
