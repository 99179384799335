import React, { useState, useEffect, SetStateAction } from "react";
import {
    Dialog,
    Form,
    FormInput,
    Divider,
    RadioGroup,
    FormButton,
    FormCheckbox,
    CheckboxProps,
    FormDatepicker,
    Provider,
    Flex,
    FlexItem,
} from "@fluentui/react-northstar";
import CreatableCompanySearch from "./CreatableCompanySearch";
import * as Backend from "../actions/backendCalls";
import CreatableProjectSearch from "../components/CreatableProjectSearch";
import { IDropdownElement } from "./WeekTable";
import { IProject } from "../types/types";

type addProjectDialogTypes = {
    dropdownArr: IDropdownElement[] | null;
    decodedToken: any;
    passChildData?: any;
    token: SetStateAction<string> | string;
    isAdmin: boolean;
    handleSelectedProject: any;
};

const AddProjectDialog = ({
    dropdownArr,
    decodedToken,
    passChildData,
    token,
    isAdmin,
    handleSelectedProject,
}: addProjectDialogTypes) => {
    const addNewCompany = "Add new company";
    const [projects, setProjects] = useState([""]);
    const [companies, setCompanies] = useState([addNewCompany]);
    const [selectedCompany, setSelectedCompany] = useState();
    const [users, setUsers] = useState<any[]>([]);
    const [selectedProject, setSelectedProject] = useState<string | number>();
    const [decodedTok, setDecodedToken] = useState<any>(decodedToken);
    const [popup, setPopup] = useState<boolean>(false);
    const [jwtoken, setJWtoken] = useState("");
    const [newProject, setNewProject] = useState<boolean>(false);
    const [newCompany, setNewCompany] = useState<boolean>(false);
    const [userOID, setUserOID] = useState<any[]>([decodedTok.oid]);
    const [isPrivate, setIsPrivate] = useState<boolean>(true);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<number | null>(null);
    const [endDate, setEndDate] = useState<number | null>(null);
    const [locked, setLocked] = useState<boolean | undefined | "mixed">(false);

    useEffect(() => {
        setDecodedToken(decodedToken);
        setUserOID([decodedToken.oid]);
    }, [decodedToken]);

    useEffect(() => {
        // console.log("jwToken received");
        setJWtoken(token);
    }, [token]);

    useEffect(() => {
        if (jwtoken !== "") {
            getUsers();
        }
    }, [jwtoken]);

    useEffect(() => {
        if (newProject) {
            passChildData(projects);
            setNewProject(false);
        }
        if (newCompany) {
            passChildData(companies);
            setNewCompany(false);
        }
    }, [projects, newProject, companies, newCompany]);

    const getUsers = async () => {
        await Backend.getUsers(jwtoken)
            .then((users) => setUsers(users))
            .catch((err) => console.log(err));
    };

    // Handle add project button (opens dialog)
    const handleAdd = (value) => {
        setPopup(value);
    };

    const createNewProject = async (name, companyID) => {
        const body: IProject = {
            company_id: companyID,
            owner_oid: decodedTok.oid,
            project_name: name,
            members: userOID,
            admins: [],
            entry_locked: locked ? true : false,
            tags: ["tag1"],
            private: isPrivate,
            active: {
                from: startDate,
                to: endDate,
            },
            budget: {
                used: null,
                total: null,
            },
        };
        const ret = await Backend.createProject(jwtoken, body).catch((err) => {
            throw err;
        });
        setProjects([...projects, name]);
        setNewProject(true);
        return ret;
    };

    const handleCompanies = (company) => {
        setSelectedCompany(company.id);
        setCompanies([...companies, company.name]);
        setIsDisabled(false);
    };

    const handleStarDate = (e, data) => {
        console.log(data.value.valueOf());
        setStartDate(data.value.valueOf());
    };

    const handleEndDate = (e, data) => {
        console.log(data.value.valueOf());
        setEndDate(data.value.valueOf());
    };

    const handleDisabled = (disabled) => {
        setIsDisabled(disabled);
    };
    const handlePassedProject = (object) => {
        // console.log(object);
        setSelectedProject(object.value);
    };

    const handleOnChange = (e, d) => {
        setSelectedProject(d.value);
    };

    return (
        <Dialog
            key="dialog"
            backdrop
            open={popup}
            trapFocus
            closeOnOutsideClick={false}
            style={{
                // minWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            styles={({ theme: { siteVariables } }) => ({
                color: siteVariables.colorScheme.brand.foreground,
            })}
            onCancel={() => {
                setPopup(false);
            }}
            // cancelButton="Cancel"
            content={
                <Form
                    // style={{ minWidth: "100%" }}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        const projectName = e.target["projectName"].value;
                        if (selectedCompany) {
                            try {
                                const project = await createNewProject(
                                    projectName,
                                    selectedCompany
                                );
                                const dropdownElement: Partial<IDropdownElement> =
                                    {
                                        label: selectedCompany,
                                        project_id: project.id,
                                    };

                                handleSelectedProject(dropdownElement);
                                setPopup(false);
                            } catch (error) {
                                console.error(error);
                            }
                        }
                    }}
                >
                    <Provider.Consumer
                        render={(theme) => (
                            <CreatableCompanySearch
                                SelectedCompany={handleCompanies}
                                Styles={theme}
                                HandleDisabled={handleDisabled}
                            />
                        )}
                    />
                    <FormInput
                        // style={{ overflow: "scroll" }}
                        label="Project Name"
                        name="projectName"
                        required
                        disabled={isDisabled}
                        value={selectedProject}
                        onChange={handleOnChange}
                    />
                    <Divider />
                    <FormDatepicker
                        // style={{ overflow: "scroll" }}
                        label="Select active start date"
                        onDateChange={handleStarDate}
                        disabled={isDisabled}
                    />
                    <FormDatepicker
                        // style={{ overflow: "scroll" }}
                        label="Select active end date"
                        onDateChange={handleEndDate}
                        disabled={isDisabled}
                    />
                    {isAdmin ? (
                        <RadioGroup
                            vertical
                            defaultCheckedValue="private"
                            items={["private", "public"].map((group) => ({
                                key: group,
                                value: group,
                                name: "group",
                                label: group,
                                "aria-label": "orange",
                            }))}
                            onCheckedValueChange={(e, props: any) => {
                                // eslint-disable-next-line react/prop-types
                                if (props.value == "public") {
                                    const oidArr: any[] = [];
                                    for (const user in users) {
                                        oidArr.push(users[user].oid);
                                    }
                                    oidArr.push("offcodeGroup"); //HACK HACK TODO: fix me!
                                    setIsPrivate(false);
                                    setUserOID(oidArr);
                                } else {
                                    setIsPrivate(true);
                                    setUserOID([decodedTok.oid]);
                                }
                            }}
                        />
                    ) : (
                        <div></div>
                    )}
                    <Divider />
                    <FormCheckbox
                        // style={{ overflow: "scroll" }}
                        label="Entry locked"
                        toggle
                        onChange={(e, data: CheckboxProps = {}) => {
                            console.log(data.checked);
                            setLocked(data.checked);
                        }}
                        disabled={isDisabled}
                    />
                    <Flex>
                        <FormButton
                            primary
                            // style={{ overflow: "scroll" }}
                            content="Submit"
                            disabled={isDisabled}
                        />
                        <FlexItem push>
                            <FormButton
                                primary
                                // style={{ overflow: "scroll" }}
                                content="Cancel"
                                onClick={() => setPopup(false)}
                                // disabled={isDisabled}
                            />
                        </FlexItem>
                    </Flex>
                </Form>
            }
            // header="Add new project"
            trigger={
                <div>
                    {/* <Button
                        key="addButton"
                        content="Add new project"
                        icon={<AddIcon />}
                        id="1"
                        onClick={handleAdd}
                    /> */}
                    <Provider.Consumer
                        render={(theme) => (
                            <CreatableProjectSearch
                                ProjectList={dropdownArr}
                                SelectedProject={handleSelectedProject}
                                PassSelectedProject={handlePassedProject}
                                Styles={theme}
                                SetPopUp={handleAdd}
                            />
                        )}
                    />
                </div>
            }
        />
    );
};

export { AddProjectDialog };
